import React from 'react';

const Footer = () => {
  return (
    <div>
      <p>© 2023 Drop Zone Live LLC All Rights Reserved</p>
    </div>
  );
};

export default Footer;
